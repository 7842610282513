import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import AppMenu from "../../menu";
import TablesData from "../globalTable";
import TableItem from "../globalTable/tableItem";
import { SearchOnly } from "../globalTable/searchandfilter";
import HorizontalTabs from "../../tabs/horizontalTabs";
import {
  fetchEnrolleesDrugFill,
  getEnrolleesDrugFill,
} from "../../../store/drugRefill";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../common/Loader";
import { getFormatedDate } from "../../../utils/constants";
import otherPlan, { fetchOtherPlan, getOtherPlan } from "../../../store/otherPlan";
import { fetchRequestLog, getRequestLog } from "../../../store/requestLog";

const tabLabels = ["Principal Directory", "Dependent Directory"];

export default function RequestLogTable() {
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState([]);
  const dispatch = useDispatch();
  const othersData = useSelector(getRequestLog);
  let [pageNumber, setPageNumber] = useState(1);
  let [pageLists, setPageLists] = useState(10);
  const { activetab } = useParams();

  console.log('otherdata',othersData);

  const tabNameToIndex = {
    0: "principal-directory",
    1: "dependent-directory",
  };

  const indexToTabName = {
    "principal-directory": 0,
    "dependent-directory": 1,
  };

  const [tabIndex, setTabIndex] = useState(indexToTabName[activetab]);
  const onChangeTabIndex = (e, newValue) => {
    history.push(`/hmo/dashboard/requestlog/${tabNameToIndex[newValue]}`);
    setTabIndex(newValue);
  };

  useEffect(() => {
    const fetchEnrollees = async () => {
      dispatch(fetchRequestLog(pageLists, pageNumber));
    };
    fetchEnrollees();
  }, [dispatch, pageNumber, pageLists]);

  const incrementPageNumber = async () => {
    setPageNumber((pageNumber += 1));
    await dispatch(fetchRequestLog(pageLists, pageNumber));
  };

  const decrementPageNumber = async () => {
    setPageNumber((pageNumber -= 1));
    await dispatch(fetchRequestLog(pageLists, pageNumber));
  };

  const handleSelectNumber = ({ target }) => {
    if (target.value === "30") {
      setPageLists(30);
    } else if (target.value === "20") {
      setPageLists(20);
    } else {
      setPageLists(10);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  // const handleSearchAndFilter = othersData.otherPlanList.filter(
  //   (searched) => {
  //     if (search === " ") {
  //       return searched;
  //     } else if (
  //       searched.first_name
  //         .toLowerCase()
  //         .includes(search && search.toLowerCase()) ||
  //       searched.last_name
  //         .toLowerCase()
  //         .includes(search && search.toLowerCase())
  //     ) {
  //       return searched;
  //     } else {
  //       return null;
  //     }
  //   }
  // );

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleSelectAllClick = () => {
    const newSelecteds = othersData.requestLogList.map((n) => n.id);
    setSelected(newSelecteds);
    return;
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          marginBottom: "2rem",
          justifyContent: "space-between",
        }}
      >
        <SearchOnly handleSearch={handleChange} searchWord={search} />
        <HorizontalTabs
          tabData={tabLabels}
          tabIndex={tabIndex}
          onTabChange={onChangeTabIndex}
        />
        {/* <FilterOnly /> */}
      </div>
      <Loader visible={othersData.loading} />
      <TableItem
        userType="Enrollee"
        checkBoxes
        tabHeadLabel={[
          "Enrollee",
          "Service type",
          "created",
        ]}
        action
        tableSize
        tabToolBar
        tabHeadCheck
        numSelectedItem={selected?.length}
        rowCount={othersData?.requestLogList?.length}
        numSelected={selected?.length}
        onSelectAllClick={handleSelectAllClick}
        currentPage={othersData?.currentPage}
        toPage={othersData?.toPage}
        totalPage={othersData?.total}
        handleIncrement={() => incrementPageNumber()}
        handleDecrement={() => decrementPageNumber()}
        disabledIncrement={pageNumber >= othersData?.lastPage}
        disabledDecrement={pageNumber <= 1}
        handleSelectPageNumber={handleSelectNumber}
        pageSelectValue={pageLists}
        tabBodyData={othersData?.requestLogList?.map((item, i) => {
          const isItemSelected = isSelected(item.id);
          const labelId = `enhanced-table-checkbox-${i}`;
          return (
            <TablesData
              labelId={labelId}
              handleItemClick={(event) => handleClick(event, item.id)}
              aria-checked={isItemSelected}
              isItemSelected={isItemSelected}
              key={i}
              checkBoxes
              image
              imgInitials={
                item?.user_details.first_name?.slice(0, 1) + item?.user_details?.last_name?.slice(0, 1)
              }
              enroleeName={item?.user_details.first_name + ' ' + item?.user_details.last_name}
              enroleeId={""}
              listItem={[
                item?.request_type,
                getFormatedDate(item?.created_at, "YYYY/MMM/DD"),
              ]}
              action={
                <AppMenu
                  menu_name_or_icon={<MoreHorizIcon />}
                  item_names={[
                    {
                      "View More": () => {
                        localStorage.setItem('requestlog',JSON.stringify(item));
                        history.push(
                          `/hmo/dashboard/requestlog/${
                            item.enrollee_primary_key || item.id
                          }`
                        )
                      },
                    },
                  ]}
                />
              }
            />
          );
        })}
        tablePagination
      /> 
    </>
  );
}
