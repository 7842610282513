import React, { useState } from "react";
import Paper from "../../common/Paper";
import TabPanel from "../../common/TabPanel";
import Tabs from "../../common/Tabs";
import Title from "../../common/Typography/title";
import { getUsers } from "../../../store/user";
import { useSelector } from "react-redux";
import { getFormatedDate } from "../../../utils/constants";
import styles from "./index.module.css";

const BuyOtherDetails = ({ }) => {
  const details = JSON.parse(localStorage.getItem('othersdetail'));
  const users = useSelector(getUsers);
  const user = users?.data ? users.data.user_data : {};
  const services = details?.data?.services || JSON.parse(details?.data?.services) ;
  const cancerDetails = users?.serviceDetails ? users.serviceDetails : {};

  const [showMore, setShowMore] = useState(false);




  return (
    <Paper className="mb-5">
      <Tabs
        activeTab={"Request Details"}
        tabData={["Request Details", "", "", "", ""]}
        tabIndex={0}
        onTabChange={(index) => console.log(index)}
      />

      <div className={styles["tab-panel-body"]}>
        <TabPanel value={0} index={0}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>ID</th>
                <th>User Name</th>
                <th>Bought For</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>{details?.id}</td>
                <td>{details?.name}</td>
                <td style={{ textTransform: "none" }}>{details?.data?.first_name}{' '}{details?.data?.last_name}</td>
              </tr>
            </tbody>

            <thead>
              <tr className={styles["table-row"]}>
                <th>Phone Number</th>
                <th>hospital</th>
                <th>Request type</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>{details?.data?.phone}</td>
                <td>
                  {details?.data?.hospital_name}
                </td>
                <td>{details?.data?.request_type}</td>
              </tr>
            </tbody>

            <thead>
              <tr className={styles["table-row"]}>
                <th>Hospital Address</th>
                <th>Appointment Date</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>{details?.data?.hospital_address}</td>
                <td>
                  {getFormatedDate(
                    details?.data?.appointment_date
                  )}
                </td>
              </tr>
            </tbody>

            <thead>
              <tr className={styles["table-row"]}>
                <th>Other Sevices</th>
               
                
              </tr>
            </thead>

            <tbody>
              <tr className={styles["table-row"]}>
                <td className={styles["benefits-container"]}>
                  {services?.fields?.length > 0 ? services?.fields?.map((service) => (
                    <div key={service}>{service}</div>
                  ))
                  :
                  services
                
                }
                  {/* {!showMore && services && (
                    <button
                      className={styles["show-more-btn"]}
                      onClick={() => setShowMore(true)}>
                      {cancerDetails?.sub_services?.length - 7 > 0 &&
                        `+${cancerDetails?.sub_services?.length - 7} More`}
                    </button>
                  )} */}
                </td>
               
              </tr>
            </tbody>
          </table>
        </TabPanel>
      </div>
    </Paper>
  );
};

export default BuyOtherDetails;
