import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/api";

let slice = createSlice({
  name: "requestLog",
  initialState: {
    requestLogList: [],
    total: 10,
    currentPage: 1,
    toPage: null,
    lastPage: null,
    loading: false,
  },
  reducers: {
    fetchedRequestLogSuccess: (enrollee, action) => {
      enrollee.loading = false;
      enrollee.requestLogList = action.payload.data;
      enrollee.total = action.payload.meta.total;
      enrollee.currentPage = action.payload.meta.from;
      enrollee.toPage = action.payload.meta.to;
      enrollee.lastPage = action.payload.meta.last_page;
      console.log('data',action.payload);
    },
    
    fetchedRequestLogStart: (enrollee, action) => {
      enrollee.loading = true;
    },
    fetchedRequestLogFailed: (enrollee, action) => {
      enrollee.loading = false;
    },
  },
});

const {
  fetchedRequestLogSuccess,
  fetchedRequestLogStart,
  fetchedRequestLogFailed,
} = slice.actions;

export default slice.reducer;
const url = "/v1/admin/request-audit";

export const fetchRequestLog = (pageItems, page) =>
  apiCallBegan({
    url: `${url}?page_items=${pageItems}&page=${page}`,
    method: "GET",
    onSuccess: fetchedRequestLogSuccess.type,
    onStart: fetchedRequestLogStart.type,
    onError: fetchedRequestLogFailed.type,
  });


  



export const getRequestLog = (state) => state.entities.enrolleeRequestLog;

// export const getPendingDrugRefillById = (state) => (id) =>
//   state.entities.enrolleesDrugFill.pendingDrugFill.find(
//     (drug) => drug.id === id
//   );
